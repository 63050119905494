import React from "react";
import config from '../../config';
const WellcomeSection = () =>{
    return(
        <>
            <section className="wellcome-section-2">
                <div className="leaf-position-right">
                    <img src={config.FILE_URL + 'leaf-1.png'} alt="" className="img-fluid py-5" />
                </div>
                <div className="container">
                    <div className="page-title text-center pt-0">
                        <p>Contact <span>Us</span></p>
                    </div>                    
                    <div className="wellcome-content1 mb-5">
                        <div className="text-center col-7 mx-auto">
                            <div className="text-center">
                                <h4 className="fw-bold">Grameen Shakti Samajik Byabosha Ltd.</h4>
                                <p>Telecom Bhaban (Level - 7) 53/1 Box Nagar, Zoo Road,<br/>Mirpur, Dhaka-1216
                                <br/>+880 01717 00000
                                <br/>grameenssbl@gmail.com
                                <br/>https://grameenssbl.org/</p>
                            </div>
                        </div>
                    </div>                    
                    <div classsName="contact-form col-lg-8 col-md-9 col-sm-12 mx-auto my-5 border">
                         <div className="col-lg-7 col-md-6 col-sm-10 mx-auto">
                            <div className="contact-area">
                                <div className="contact-form my-5">
                                    <form>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="name" placeholder="Name" required="" value=""/>
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" name="email" placeholder="Email" required="" value=""/>
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="subject" placeholder="Subject" required="" value=""/>
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control" name="message" rows="5" placeholder="Message" required="" value=""></textarea>
                                        </div>
                                        <button className="btn btn-submit ssbl-btn-primary" type="submit">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="maps my-5 row mx-auto p-4 image-box">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.1431004374252!2d90.35027399427038!3d23.808298613414696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c10068999409:0x8ac598112cb39823!2sGSSBL!5e0!3m2!1sen!2sbd!4v1717672012679!5m2!1sen!2sbd" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Google Map" className="shadow p-0 rounded "></iframe>
                    </div>                                                          
                </div>
            </section>
        </>
        
    )
}
export default WellcomeSection;