import React from 'react';
// import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Procedure =  ({reportData}) => {

  return (
  <div>
    <section className='principles'>
        <div className='container'>
            <div className='row'>
              <div className='container py-2'>
                  <div className='section-title text-center'>
                     <p>Working <span>Procedure </span></p>
                  </div>
                  <div className='col-10 mx-auto'>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end' style={{lineHeight: '200px'}}>01</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Access for Entrepreneurs (Screening Exercise)</h3>
                            <p>
                            The Screening Exercise is the first stage of Nobin Udyokta-NU (New Entrepreneur) Program of GTT, that scouts young and aspiring entrepreneurs who are children of Grameen Bank borrowers.
                            <br/>
                            It aims to spread the message of the objectives of the NU program and the idea of social business among aspiring entrepreneurs.                              
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Concept, Process, Reporting and Regulatory Issues Explained</h3>
                            <p>
                            Nobin Udyokta's are then helped to create Business plan for their business as a preparation to present their business to GTT for investment. They are also informed on regulatory issues related to their business.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>02</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>03</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Social Business Design Lab</h3>
                            <p>
                            Design lab is an interactive event where the NU’s present their business and proposes for investment. The panelist analyzes the feasibility of the business proposals and approve the investment accordingly.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Agreement Signing</h3>
                            <p>
                            After the business proposal is approved, a contract is signed between the NU and GTT after which the funds are disbursed to their bank accounts.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>04</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '100px'}}>05</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Reporting through SMS</h3>
                            <p>
                            Daily reporting through SMS is provided by the NU. Financial reports are shared monthly.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Training on Financial Literacy</h3>
                            <p>
                            NU’s are given training on book keeping & business strategies.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '100px'}}>06</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>07</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>On and off-site monitoring</h3>
                            <p>
                            Through a process of reporting and mentorship, Nobin Udyokta’s are guided to help them keep their business compliant and on track.
                            </p>
                        </div>
                      </div>
                    </div>                    
                  </div>
              </div>
            </div>
        </div>       
    </section>
    <section className='nobin program-principles my-0 py-0' id='status'>
        <div className='container'>
            <div className='row'>
                <div className="page-title text-center">
                    <p>Capacity<span> Building & Mentoring</span></p>
                </div>
                <div className='col-10 mx-auto'>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end' style={{lineHeight: '200px'}}>01</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Sending of SMS regularly</h3>
                            <p>
                            o ensure success of Nobin Udyokta’s (young entrepreneur’s), Nobin Udyokta’s regularly send SMS updates of their financials. This allows us to monitor and guide the entrepreneurs to stay on track.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Expected Sales after investment</h3>
                            <p>
                            After an investment is made, we regularly monitor expected sales as assessed in the Business Plan to ensure healthy revenue generation.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>02</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>03</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Expected Expenditure after investment</h3>
                            <p>
                              The road to success for entrepreneurs is to stay on track of expenditures to ensure good cash-flows. We monitor and mentor the young entrepreneurs to manage expenses wisely.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Monthly Receipt-Payment Statement</h3>
                            <p>
                              Book Keeping is essential to run a healthy business. Our officers help empower the young entrepreneurs for regular record keeping.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>04</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '100px'}}>05</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Investment Payback</h3>
                            <p>
                              With guidance to managing expected revenues, healthy cash flow by managing expenses, Nobin Udyokta’s stay on track paying investment installment on time.
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Asset Increase</h3>
                            <p>
                              Ensuring healthy growth in asset allow Nobin Udyokta’s to not only grow their business but helps them secure their business.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '100px'}}>06</p>
                        </div>
                      </div>                      
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '130px'}}>07</p>
                        </div>
                      </div>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Employment Generation</h3>
                            <p>
                              Growth is essential to any business. Employment generation is a great indicator to the success of Nobin Udyoktas.
                            </p>
                        </div>
                      </div>
                    </div>  
                    <div className='principles-text row'>
                      <div className='col-md-11 col-lg-11 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <h3>Behavior, Social Activities and Participation</h3>
                            <p>
                              As Nobin Udyokta’s pave their success in business, it is important that they give back to their community through social activities, helping the community and participating in community development.
                            </p>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 col-sm-12 text-center'>
                        <div className='principles-number' style={{textAlign: 'right !important'}}>
                            <p className='text-end pb-0' style={{lineHeight: '100px'}}>08</p>
                        </div>
                      </div>                      
                    </div>                                      
                  </div>                
            </div>
        </div>
    </section>  
  </div>  
  )
}

export default Procedure;

