/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState }  from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper/modules';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import axios from 'axios';
// import { Link } from 'react-router-dom'; 
import config from '../../config';
const TopBanner = () =>{
    const [sliderJsonData, setSliderJsonData] = useState([]);

    async function fetchSliderData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.SLIDER_URL);
            if(response.status === 200){
                 setSliderJsonData(response.data.data);
                 console.log(sliderJsonData);
            }
            else {
                console.error('Unexpected status code:', response.status);
            }            
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }// siteinfo function 
    useEffect(() => {  
        fetchSliderData();
      }, []);
    return(
        <>
            <section className='slider-section'>
                <div className='slider-body'>
                    <div className='container-fluid'>
                        <div className="row slider-area">
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                            >    
                                <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "slider2.png"} alt='' className="img img-fluid w-100"/>
                                    {/* <div className='slider-headline d-none d-md-block' style={{backgroundColor: 'rgba(134, 174, 51, 0.8)'}}>
                                        <p className='slider-title'>Grameen Shakti Samajik <br/> Byabosha Ltd.</p>
                                        <p className='slider-content'>working towards poverty eradication and bringing social equity by creating new entrepreneurs (Nobin Udyokta)</p>
                                    </div> */}
                                </SwiperSlide>                            
                                <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "slider1.png"} alt='' className="img img-fluid w-100"/>
                                    <div className='slider-headline d-none d-md-block' style={{backgroundColor: 'rgba(134, 174, 51, 0.8)'}}>
                                        <p className='slider-content'>People should wake up in the Morning and say 'I am not a job seeker, I am a job-creator'.</p>
                                        <p className='slider-title text-end'>&mdash; Dr. Muhammad Yunus.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TopBanner;