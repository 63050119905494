import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { Link } from 'react-router-dom'; 
import DOMPurify from 'dompurify';

const BoardMembers =  ({reportData}) => {
  const location = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);

  // const [bodData, setBodData] = useState([]); 
  const [bodJsonData, setBodJsonData] = useState([]);

  async function fetchBodData() {
      try {
          const response = await axios.get(config.BASE_URL + 'json_data/board_member.json');
          console.log('_bod', response);
          if(response.status === 200){
              setBodJsonData(response.data);
          }
          else {
              console.error('Unexpected status code:', response.status);
          }
      } catch (error) {
          console.error('Error reading JSON file:', error);
      }
  } 
useEffect(() => {
  // fetchSiteInfo();
  fetchBodData();
  // fetchCompanySliderData();
}, []);

  return (
    <section className='board-members py-4'>
          <div className="page-title text-center">
              <p>Board of<span> Directors</span></p>
          </div>
        <div className='container'>
            {bodJsonData.length > 1 && (
            <div className='chairman' key={bodJsonData[0].id}>
              <Link to={`${config.BASE_URL}about-us-details/${bodJsonData[0].id}`} className='link-text-hrf'>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <div className='chairman-img'>
                      <img src={`${config.FILE_URL}${bodJsonData[0].profile_img}`} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className='col-md-8 col-sm-12'>
                    <div className='chairman-text'>
                      <p className='name' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bodJsonData[0].name) }}></p>
                      <p className='designation'>{bodJsonData[0].designation}</p>
                      <p className='content'>{bodJsonData[0].description}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
            
            <div className='members'>
              <div className='row'>
                  {bodJsonData.length > 1 && bodJsonData.slice(1).map((item) => (
                      <div className='col-md-6 col-sm-12' key={item.id}>
                        <Link to={config.BASE_URL + './about-us-details/' + item.id} className='link-text-hrf'>
                          <div className='member d-flex'>
                            <div className='member-img'>
                                <img src={config.FILE_URL + item.profile_img} alt="" className="img-fluid" />
                            </div>
                            <div className='member-text text-center w-100'>
                                <p className='name' dangerouslySetInnerHTML={{ __html: item.name }}></p>
                                <p className='profession'>{item.designation}</p>
                            </div>
                          </div>
                          </Link>
                      </div>
                  ))}              
                </div>
            </div>
        </div>
    </section>
  )
}

export default BoardMembers;

