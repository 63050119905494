import React from "react";
const WellcomeSection = () =>{    
    return(
        <>
            <section className="wellcome-section m-0">
                <div className="container">
                    <div className="row">
                        <div className="page-title ">
                            <p className="mt-5">GSSBL <span>News</span></p>
                        </div>               
                    </div>         
                </div>
            </section>
        </>
    )
}
export default WellcomeSection;