import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const WellcomeSection = () =>{
    // const [shortInfoJasonData, setShortInfoJasonData] = useState([]);

    // async function fetchShortInfo() {
    //     // try {
    //     //     const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL);
    //     //     if(response.status === 200){
    //     //         setShortInfoJasonData(response.data.data[0]);
    //     //    }
    //     //    else {
    //     //        console.error('Unexpected status code:', response.status);
    //     //    }
    //     // } catch (error) {
    //     //     console.error('Error reading JSON file:', error);
    //     // }
    // }
    useEffect(() => {
        // fetchShortInfo();
      }, []);
    return(
        <>
            <section className="wellcome-section">
                    <div className="container">
                        <div className="page-title py-0">
                            <p>GSBL <span>Gallery</span></p>
                        </div>
                    </div>
            </section>
        </>
        
    )
}
export default WellcomeSection;