import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Wellcome from './Wellcome';
import config from '../../config';
// import FaqArea from './FaqArea'
function NewsPage() {
  // const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  // useEffect(() => {
    // window.scrollTo(0, 0); // Scroll to the top
  // }, [location]);
    return(
        <div>
            <Wellcome />
            <section className="container news-section">
              <div className="row">
                <div class="col-12 mb-4 shadow rounded">
                  <div class="card2 border-0 d-flex">
                    <div className='news-img col-3'>
                      <img src={config.FILE_URL + 'ev4.webp'} alt="" class="card-img-top h-100"/>
                    </div>
                    <div class="card-body px-3 mt-2">
                      <a href="./news-details/1" className='read-more'>
                        <h5 class="card-title mt-0 fw-bold">
                          MUHAMMAD YUNUS is ready with his third book Building Social..
                        </h5>
                      </a>
                      <p class="card-text mt-1">
                        Professor Muhammad Yunus, the practical visionary who pioneered microcredit and, with his Grameen Bank, won the 2006 Nobel Peace Prize, will be launching his new book “Building Social Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism Business: The New Kind of Capitalism that Serves Humanity’s Most Pressing of Capitalism that Serves Humanity’s Most...                      
                        <a href="./news-details/1" className='read-more mx-2'>Read More</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-4 shadow rounded">
                  <div class="card2 d-flex border-0">
                    <div className='news-img col-3'>
                      <img src={config.FILE_URL + 'news.png'} alt="" class="card-img-top h-100"/>
                    </div>
                    <div class="card-body px-3 mt-2">
                      <a href="./news-details/2" className='read-more'>
                        <h5 class="card-title mt-0 fw-bold">
                          ৩৭৫ তম সামাজিক ব্যবসার ডিজাইন ল্যাবে স্যানিটারি নেপকিন ও পানীয় ...
                        </h5>
                      </a>
                      <p class="card-text mt-1">
                        গতকাল ২০ অক্টোবর ২০১৬  গ্রামীণ ব্যাংক অডিটেরিয়ামে ইউনূস সেন্টার কর্তৃক  আয়োজিত ৩৭৫ তম সামাজিক ব্যবসার ডিজাইন ল্যাব অনুষ্ঠিত হয়। বিভিন্ন জাতীয় ও আন্তর্জাতিক সংস্থা থেকে প্রায় ১৬০ জন অংশগ্রহণকারী এই ল্যাবে অংশগ্রহণ করেন । নোবেল লরিয়েট প্রফেসর মুহাম্মদ ইউনূস ডিজাইন ল্যাবে সভাপতিত্ব করেন  ...                      
                        <a href="./news-details/2" className='read-more mx-2'>Read More</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-4 shadow rounded">
                  <div class="card2 d-flex border-0">
                    <div className='news-img col-3'>
                      <img src={config.FILE_URL + 'ev3.webp'} alt="" class="card-img-top h-100"/>
                    </div>
                    <div class="card-body px-3 mt-2">
                      <a href="./news-details/3" className='read-more'>
                        <h5 class="card-title mt-0 fw-bold">
                          Nobel Laureate Muhammad Yunus Granted Bail in Bangladesh Graft Case
                        </h5>
                      </a>
                      <p class="card-text mt-1">
                      Yunus, who was awarded the Nobel Peace Prize in 2006 for pioneering the use of microcredit to help impoverished people, was sentenced to six months in prison in January on a separate charge of violating labor laws six months in prison in January on a separate charge of violating labor laws six months in prison in January on a separate charge of violating labor laws. He was granted bail in that case too and has appealed....                      
                        <a href="./news-details/3" className='read-more mx-2'>Read More</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <nav aria-label="Page navigation example text-center">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#0" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#0">1</a></li>
                  <li class="page-item"><a class="page-link" href="#0">2</a></li>
                  <li class="page-item"><a class="page-link" href="#0">3</a></li>
                  <li class="page-item"><a class="page-link" href="#0">5</a></li>
                  <li class="page-item"><a class="page-link" href="#0">5</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#0" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
        </div>
    )
}
export default NewsPage;