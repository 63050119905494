import React, { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';

// import FaqArea from './FaqArea'
function CareerDetails() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
      <section className="wellcome-section-2 career-details-page">
      <div className="container">
          <div className="page-title">
              <p>GSSBL <span>Career Details</span></p>
          </div>
          <div className="wellcome-content p-4">
                <h4 className="fw-bold">GSSBL is looking for Sr. Officer IT</h4>              
                <p>
                    We are one of the leading Merchant Banks of the country operating in the capital market since 1998.
                </p>
                <p>Vacancy: 1 (One)</p>

                <h4>Job Context: </h4>
                <p>We are looking for a Officer/ Senior Officer for our IT Department.</p>

                <h4>Job Responsibilities:</h4>
                <p>
                    Assist Head of IT to -<br/>
                    Record all types of transactions in the customer portfolio and company portfolio under merchant banking software. 
                    Managing the day to day operations of the portfolio of corporate clients.
                    Reconciliation of the shares /securities displayed in the software with the CDBL.
                    Collection and posting of cash dividends and stock dividends.
                    Update the daily share price, verifying the accuracy and delivery portfolios to the customers through E-mail. <br/>
                    Any other duties assigned by the higher management.
                </p>

                    
                <p>Employment Status: Full-time</p>

                <h4>Educational Requirements: </h4>
                <p>Graduate / Diploma in CSE </p>


                <h4>Experience Requirements:</h4>
                <p>
                    Minimum 5 years in Merchant Banker/ Brokerage House.
                    Work Experience in Cygnus Software shall be preferred.
                </p>


                <h4>Additional Requirements: </h4>
                <p>
                    Excellent IT skills, including a working knowledge of presentation software packages.
                    Strong Written and Verbal communication skills.
                    Skill in Data Analysis & Presentation
                    Good command in Photoshop & Illustrator.
                </p>
                <p>Job Location: Company Head Office</p>

                <h4>Salary</h4>
                <p>Negotiable. Other benefits as per company policy.</p>
                
                <h4>Apply Procedure: </h4>
                <p>
                    If you think, you are the right person for the required position, please send your updated-resume along with an application and attached passport size photo to the HR Department, Grameen Capital Management Ltd. Telecom Bhaban (4th Floor, D Block), 
                    53/1, Box Nagar, Zoo Road, Mirpur-1, Dhaka-1216 or through
                </p>
                <p>E-mail: career@grameencapitalbd.com</p>
          </div>
          <div className=" mx-auto my-5">
              <h3 className="fw-bold text-center my-5">Apply Now</h3>
              <div className="mb-3">
                  <input type="text" className="form-control" name="subject" placeholder="Enter Your Name !" required="" value=""/>
              </div>
              <div className="mb-3">
                  <input type="text" className="form-control" name="subject" placeholder="Enter Your Email !" required="" value=""/>
              </div>
              <div className="mb-3">
                  <input type="text" className="form-control" name="subject" placeholder="Enter Your Phone Number !" required="" value=""/>
              </div>
              <div className="mb-3">
                  <textarea className="form-control" name="message" rows="10" placeholder="Cover Letter !" required="" value=""></textarea>
              </div>  
              <div className="mb-3">
                <h6>Upload Your CV</h6>
                <input className="form-control" type="file" id="formFile" accept=".pdf" name="cv_file" required="" />
                <small><strong>Only pdf</strong></small>
              </div>
              <button className='btn ssbl-btn-primary'>Submit</button>
          </div>
      </div>
  </section>
    )
}
export default CareerDetails;