import React from 'react';

import axios from 'axios';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';

import Footer from './Components/Footer';
import Header from './Components/Header';

import HomePage from './Components/HomePage/HomePage';
import SocialBusiness from './Components/SocialBusiness/SocialBusiness';
import AboutPage from './Components/AboutPage/AboutPage';
import AboutPageDetails from './Components/AboutPage/AboutPageDetails';
import ProgramPage from './Components/ProgramPage/ProgramPage';
import AreaOfImpactPage from './Components/AreaOfImpactPage/AreaOfImpactPage';
import ExposureVisits from './Components/LearningCentrePage/ExposureVisits';
import Trainings from './Components/LearningCentrePage/Trainings';
import SuccessStoryPage from './Components/SuccessStoryPage/SuccessStoryPage';
import GalleryPage from './Components/GalleryPage/GalleryPage';
import NewsPage from './Components/NewsPage/NewsPage';
import NewsDetails from './Components/NewsPage/NewsDetails';
import EventPage from './Components/EventPage/EventPage';
import ContactPage from './Components/ContactPage/ContactPage';
import CareerPage from './Components/CareerPage/CareerPage';
import CareerDetails from './Components/CareerPage/CareerDetails';

import NotFound from './Components/NoPage';

import { useEffect, useState } from 'react';

function App() {

  useEffect(() => {
    // fetchSiteInfo();
  }, []);

  return (
    <div>
    {/* <SiteInfoProvider> */}
      <Router>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/social-business" element={<SocialBusiness />} />
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/about-us-details/:id" element={<AboutPageDetails />} />
            <Route path="/our-programs" element={<ProgramPage />} />
            <Route path="/area-of-impact" element={<AreaOfImpactPage />} />
            <Route path="/exposure-visits" element={<ExposureVisits />} />
            <Route path="/trainings" element={<Trainings />} />
            <Route path="/success-story" element={<SuccessStoryPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/news-page" element={<NewsPage />} />
            <Route path="/news-details/:id" element={<NewsDetails />} />
            <Route path="/events" element={<EventPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/career-details/:id" element={<CareerDetails />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
      </Router>
    {/* </SiteInfoProvider> */}
    </div>
  );
}

export default App;
