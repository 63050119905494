import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Status =  ({reportData}) => {

  return (
    <section className='nobin' id='status'>
        <div className='container'>
            <div className='row'>
                <div className="page-title text-center">
                    <p>Program<span> Status</span></p>
                </div>
                <div className='nobin-parts'>
                    <div className='row'>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'youth.png'} alt="" className="img-fluid" />
                            <p>Y. Enterpreneurship</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'generation.png'} alt="" className="img-fluid" />
                            <p>Employement Generation</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'innovation.png'} alt="" className="img-fluid" />
                            <p>Social Innovation</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'capacity.png'} alt="" className="img-fluid" />
                            <p>Capacity Building</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'goals.png'} alt="" className="img-fluid" />
                            <p>SDG Goals</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'env.png'} alt="" className="img-fluid" />
                            <p>Environment</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'gender.png'} alt="" className="img-fluid" />
                            <p>Gender Inclusivity</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'dollar.png'} alt="" className="img-fluid" />
                            <p>30K+ Entrepreuners</p>
                        </div>
                        <div className='col-md-2 text-center nobin-part my-3'>
                          <img src={config.FILE_URL + 'district.png'} alt="" className="img-fluid" />
                            <p>12+ District</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Status;

