import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

import { Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper/modules';
// import required modules
import { Autoplay } from 'swiper/modules'

const Events =  ({reportData}) => {

  return (
    <section className='events'>
        <div className='container'>
            <div className='row'>
              <div className="page-title text-center">
                <p>Our <span>Events</span></p>
              </div>
              <section className='slider-section text-center'>
                <div className='slider-body'>
                    <div className='container-fluid'>
                        <div className="row slider-area">
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                            >    
                            {/* {sliderJsonData.length > 0 && sliderJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumb} alt={item.title} className="img img-fluid"/>
                                </SwiperSlide>
                            ))}  */}
                                <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "events-slider1.png"} alt='' className="img img-fluid w-100"/>
                                    <div className='slider-headline text-start'>
                                        <p className='slider-title'>Event Title</p>
                                        <p className='slider-content'>Telecom Bhaban, Mirpur-1, Dhaka</p>
                                    </div>
                                </SwiperSlide>                            
                                {/* <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "slider1.png"} alt='' className="img img-fluid w-100"/>
                                    <div className='slider-headline'>
                                        <p className='slider-title'>Grameen Shakti Samajik <br/> Byabosha Ltd.</p>
                                        <p className='slider-content'>working towards poverty eradication and bringing<br/> social equity by creating new entrepreneurs <br/>(Nobin Udyokta)</p>
                                    </div>
                                </SwiperSlide>                             */}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </div>
    </section>
  )
}

export default Events;

