// import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';
// import { SiteInfoContext } from '../SiteInfoContext';
// import TranslationComponent from './TranslationComponent';
function Header(){ 
    // const siteInfo = useContext(SiteInfoContext);
    let location = useLocation();
    return(
        <>
            <div className='top-bar '>
                <div className='container-fluid'>
                    <div className='row top-bar-area'>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-left'>
                                <div className='d-flex1'>
                                    <Link to={config.BASE_URL}>
                                        {/* <img src="http://localhost:3000/uploads/logo-primary.png" alt="" className="img-fluid"/> */}
                                        <img src={config.FILE_URL + 'logo-primary.png'} alt="" className="img-fluid" />
                                        {/* <img src={config.FILE_URL + siteInfo.logo_header} alt="" className="img-fluid" /> */}
                                    </Link> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-right d-flex justify-content-end align-items-center h-50'>                                        
                                <Link to="./contact-us" className='ssbl-btn-secondary'>Contact Us</Link>
                                <Link to="./career" className='ssbl-btn-secondary'>Career</Link>
                            </div>
                            <div className='top-bar-right-text d-flex justify-content-end align-items-center h-50'> 
                                <p>Grameen Shakti Samajik Byabosha Ltd.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className='header header-menu' id="header">
                <div className='container1 col-sm-12 ssbl-bg-primary ssbl-fc-white'>    
                    <nav className=" navbar navbar-expand-xl bg-body-tertiary mx-4 px-5">
                        <div className="container-fluid p-0">
                            <div></div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav w-100 justify-content-between">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="./">Home</Link>
                                    </li>                                
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/about-us" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            About Us
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><Link className={`dropdown-item ${location.pathname === '/about-us#aboutcompany' ? 'active' : ''}`} to="./about-us#aboutcompany">About Company</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/about-us#visionmission' ? 'active' : ''}`} to="./about-us#visionmission">Vision & Mission</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/about-us#bod' ? 'active' : ''}`} to="./about-us#bod">Board of Directors</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/about-us-details/9' ? 'active' : ''}`} to="./about-us-details/9">MD's Biography</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/social-business' ? 'active' : ''}`} to="./social-business">Social Business</Link></li>
                                        </ul>
                                    </li>                                
                                    <li className="nav-item">
                                        <Link className={`nav-link ${location.pathname === '/our-programs' ? 'active' : ''}`} to="./our-programs">Our Programs</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${location.pathname === '/area-of-impact' ? 'active' : ''}`} to="./area-of-impact">Area of Impact</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Learning Centre
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><Link className={`dropdown-item ${location.pathname === '/exposure-visits' ? 'active' : ''}`} to="./exposure-visits">Exposure Visits</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/trainings' ? 'active' : ''}`} to="./trainings">Training</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${location.pathname === '/success-story' ? 'active' : ''}`} to="./success-story">Success Story</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Media
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><Link className={`dropdown-item ${location.pathname === '/gallery' ? 'active' : ''}`} to="./gallery">Gallery</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/news-page' ? 'active' : ''}`} to="./news-page">News</Link></li>
                                            <li><Link className={`dropdown-item ${location.pathname === '/events' ? 'active' : ''}`} to="./events">Events</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>      
    ) 
}
export default Header;