// import React, { useEffect, useState } from 'react';
// import { useLocation, Link } from 'react-router-dom';
import Wellcome from './Wellcome';
import config from '../../config';
// import FaqArea from './FaqArea'
function NewsDetails() {
  // const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  // useEffect(() => {
    // window.scrollTo(0, 0); // Scroll to the top
  // }, [location]);
    return(
        <div>
            <section className="container news-details-page">
              <div className="row">
                <div class="col-10 mb-4 pt-5 mx-auto">
                  {/* <div class="card2 border-0 d-flex"> */}   
                    <div className='news-title py-3'>
                      <h1>Building Social Business: The New Kind of Capitalism</h1>
                    </div>
                    <div className='image-box p-4'>
                      <img src={config.FILE_URL + 'ev4.webp'} alt="" class="img-fluid rounded w-90"/>
                    </div>
                    <div className='text-center py-3'>
                      <small class="text-muted fw-bold">Fig: MUHAMMAD YUNUS is ready with his third book Building Social Business.</small>
                    </div>
                    <div class="news-content px-3 mt-2">
                      <p class="card-text mt-1">
                        Professor Muhammad Yunus, the practical visionary who pioneered microcredit and, with his Grameen Bank, won the 2006 Nobel Peace Prize, will be launching his new book “Building Social Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism Business: The New Kind of Capitalism that Serves Humanity’s Most Pressing of Capitalism that Serves Humanity’s Most.
                      </p>
                      <p class="card-text mt-1">
                        Professor Muhammad Yunus, the practical visionary who pioneered microcredit and, with his Grameen Bank, won the 2006 Nobel Peace Prize, will be launching his new book “Building Social Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism Business: The New Kind of Capitalism that Serves Humanity’s Most Pressing of Capitalism that Serves Humanity’s Most.
                      </p>
                      <p class="card-text mt-1">
                        Professor Muhammad Yunus, the practical visionary who pioneered microcredit and, with his Grameen Bank, won the 2006 Nobel Peace Prize, will be launching his new book “Building Social Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism Business: The New Kind of Capitalism that Serves Humanity’s Most Pressing of Capitalism that Serves Humanity’s Most.
                      </p>
                      <p class="card-text mt-1">
                        Professor Muhammad Yunus, the practical visionary who pioneered microcredit and, with his Grameen Bank, won the 2006 Nobel Peace Prize, will be launching his new book “Building Social Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism  Business: The New Kind of Capitalism Business: The New Kind of Capitalism that Serves Humanity’s Most Pressing of Capitalism that Serves Humanity’s Most.
                      </p>
                    </div>
                  {/* </div> */}
                </div>
              </div>
            </section>
        </div>
    )
}
export default NewsDetails;