import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import Welcome from './Wellcome';

function LearningCenter() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
      <div>
        <Welcome/>
      </div>
    )
}
export default LearningCenter;