import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Slider from './Slider';
import Wellcome from './Wellcome';
import Programs from './Programs';
import Nobins from './Nobins';
import Entrepreuners from './Entrepreuners';
import Events from './Events';

// import FaqArea from './FaqArea'
function HomePage() {
  const location = useLocation(); 

  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Slider/>
            <Wellcome />   
            <Programs />
            <Nobins />
            <Entrepreuners />
            <Events />
        </div>
    )
}
export default HomePage;