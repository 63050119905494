import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Principles =  ({reportData}) => {

  return (
    <section className='principles'>
        <div className='container'>
            <div className='row'>
              {/* <div className='entrepreneurs-leaf1'>
                  <img src={config.FILE_URL + 'leaf-1.png'} alt="" className='' />
              </div> */}
              <div className='container py-5'>
                  <div className='section-title d-flex'>
                     <img src={config.FILE_URL + 'leaf-1.png'} alt="" className='' />
                     <p><small></small>Seven <span>Principles </span> of <br/> <span>Social Business</span></p>
                  </div>
                  <div className='row'>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-number d-flex justify-content-end'>
                            <p className='p-0'>01</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <p className='p-4 mb-0'>Business objective will be to overcome poverty, or one or more problems (such as education, health, technology access and environment) which threaten people and society, not profit maximization.</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-content d-flex justify-content-start'>
                            <p className='p-0  mb-0'>Financial and economic sustainability</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-number'>
                            <p className='p-4'>02</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-number d-flex justify-content-end'>
                            <p className='p-0'>03</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <p className='p-4 mb-0'>Investors get back their investment amount only. No dividend is given beyond investment money</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-content d-flex justify-content-start'>
                            <p className='p-0 mb-0'>Financial and economic sustainability</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-number'>
                            <p className='p-4'>04</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-number d-flex justify-content-end'>
                            <p className='p-0'>05</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <p className='p-4 mb-0'>Environmentally conscious</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-content d-flex justify-content-start'>
                            <p className='p-0 mb-0'>Workforce gets market wage with better working conditions</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-number'>
                            <p className='p-4'>06</p>
                        </div>
                      </div>
                    </div>
                    <div className='principles-text row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className='principles-number d-flex justify-content-end'>
                            <p className='p-0'>07</p>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12 p-0'>
                        <div className='principles-content'>
                            <p className='p-4 mb-0'>---Do it with joy</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Principles;

