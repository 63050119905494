import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Nobins =  ({reportData}) => {

  return (
    <section className='map'>

        <div className='container'>
            <div className='row'>
                <div className="page-title text-center">
                    <p>Map</p>
                </div>
                <div className='entrepreneurs-leaf1'>
                    <img src={config.FILE_URL + 'leaf-2-right.png'} alt="" className='' />
                </div>
                <div className='map-img mb-5'>
                  <img src={config.FILE_URL + 'map.svg'} alt="" className="img-fluid" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Nobins;

