import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper/modules';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
// import FaqArea from './FaqArea'
function LearningCenter() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
      <section className="nobin-section mx-auto exposure-visits">
          <div className="container">
              <div className="page-title mt-5">
                  <p>Exposure <span>Visits</span></p>
              </div>
              <div className='row my-3 py-3 border p-4 rounded shadow'>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div className="row slider-area">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                          >
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev1.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>                            
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev2.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>
                          </Swiper>
                      </div>
                  </div>
                  <div className='col-md-6 col-lg-6 col-sm-12 p-5' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>
              </div>                                 
              <div className='row my-3 py-3 border p-4 rounded shadow'>
                  <div className='col-md-5 col-lg-6 col-sm-12 p-5 d-none d-md-block' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div className="row slider-area">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={450}
                            autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                          >
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev3.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>                            
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev4.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>
                          </Swiper>
                      </div>
                  </div>
                  <div className='col-md-5 col-lg-5 col-sm-12 py-5 d-md-none d-lg-none d-sm-block' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>                  
              </div>
              <div className='row my-3 py-3 border p-4 rounded shadow'>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div className="row slider-area">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                          >
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev5.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>                            
                              <SwiperSlide className="swiper-slide">
                                  <img src={config.FILE_URL + "ev1.webp"} alt='' className="img img-fluid w-100 rounded"/>
                              </SwiperSlide>
                          </Swiper>
                      </div>
                  </div>
                  <div className='col-md-6 col-lg-6 col-sm-12 p-5' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>
              </div>                                 
              <div className='row my-3 py-3 border p-4 rounded shadow'>
                  <div className='col-md-5 col-lg-6 col-sm-12 p-5 d-none d-md-block' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div className="row slider-area">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={450}
                            autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                          >
                            <SwiperSlide className="swiper-slide">
                                <img src={config.FILE_URL + "ev2.webp"} alt='' className="img img-fluid w-100 rounded"/>
                            </SwiperSlide>                            
                            <SwiperSlide className="swiper-slide">
                                <img src={config.FILE_URL + "ev3.webp"} alt='' className="img img-fluid w-100 rounded"/>
                            </SwiperSlide>
                          </Swiper>
                      </div>
                  </div>
                  <div className='col-md-5 col-lg-5 col-sm-12 py-5 d-md-none d-lg-none d-sm-block' style={{verticalAlign: 'middle'}}>
                      <h4>27 September 2018</h4>
                      <h2>-Name of Person</h2>
                      <p style={{textAlign: 'justify'}}>
                          Dummy - Visiting Location and agenda of the exposure short text..
                      </p>
                  </div>                  
              </div>
          </div>
      </section>
    )
}
export default LearningCenter;