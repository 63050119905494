import React from 'react';
import config from '../../config';
import { Link } from 'react-router-dom'; 

const Nobins =  ({reportData}) => {

  return (
    <section className='nobin'>
        <div className='nobin-leaf1'>
            <img src={config.FILE_URL + 'leaf-1.png'} alt="" className='img-fluid' />
        </div>
        <div className='container'>
            <div className='row'>
                <div className="page-title text-center">
                    <p>Nobin<span> Program</span></p>
                </div>
                <div className='nobin-parts'>
                    <div className='row'>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-center nobin-part'>
                          <img src={config.FILE_URL + 'runing-person.png'} alt="" className="img-fluid" />
                            <p>Y. Enterpreneurship</p>
                        </div>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-center nobin-part'>
                          <img src={config.FILE_URL + 'generation.png'} alt="" className="img-fluid" />
                            <p>E. Generation</p>
                        </div>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-center nobin-part'>
                            <img src={config.FILE_URL + 'innovation.png'} alt="" className="img-fluid" />
                            <p>Social Innovation</p>
                        </div>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-center nobin-part'>
                          <img src={config.FILE_URL + 'capacity.png'} alt="" className="img-fluid" />
                            <p>Capacity Building</p>
                        </div>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-center nobin-part'>
                          <img src={config.FILE_URL + 'goals.png'} alt="" className="img-fluid" />
                            <p>SDG Goals</p>
                        </div>
                        <div className='col-md-2 col-lg-2 col-sm-6 text-start nobin-part pt-2'>
                            <Link to={config.BASE_URL + './our-programs#status'} className='btn ssbl-btn-primary my-5'>
                                <span> All Programs <i className="fa fa-arrow-right"></i></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Nobins;

