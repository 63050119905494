import React, { useEffect, useState } from 'react'; 
import { useLocation, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AboutPageDetails = () => {
  const location = useLocation();
  const { id } = useParams(); // Get the ID from the URL
  const [bodJsonData, setBodJsonData] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);

  async function fetchBodData() {
    try {
      const response = await axios.get(`${config.BASE_URL}json_data/board_member.json`);
      if (response.status === 200) {
        setBodJsonData(response.data);
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error reading JSON file:', error);
    }
  }

  useEffect(() => {
    fetchBodData();
  }, []);

  useEffect(() => {
    if (bodJsonData.length > 0) {
      const member = bodJsonData.find(member => member.id === parseInt(id, 10));
      setSelectedMember(member);
    }
  }, [bodJsonData, id]);

  return (
    <section className='board-members py-4'>
      <div className='container'>
        {selectedMember && (
          <div className='chairman border-0 my-5' key={selectedMember.id}>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <div className='chairman-img mt-2'>
                  <img src={`${config.FILE_URL}${selectedMember.profile_img}`} alt="" className="img-fluid w-100 border"/>
                  <div className='text-center py-3'>
                    <h4 className='name fw-bold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedMember.name) }}></h4>
                    <p className='designation'>{selectedMember.designation}</p>
                    <div className='d-flex justify-content-center' style={{ fontSize:'15px' }}>    
                      <Link to={selectedMember.facebook} className='btn border me-2 btn-md' target='_blank'><FontAwesomeIcon icon="fa-brands fa-square-facebook" /></Link>
                      <Link to={selectedMember.linkedin} className='btn border me-2 btn-md' target='_blank'><FontAwesomeIcon icon="fa-brands fa-linkedin" /></Link>
                      <Link to={selectedMember.twitter} className='btn border me-2 btn-md' target='_blank'><FontAwesomeIcon icon="fa-brands fa-square-twitter" /></Link>
                      <Link to={selectedMember.youtube} className='btn border me-2 btn-md' target='_blank'><FontAwesomeIcon icon="fa-brands fa-square-youtube" /></Link>
                      <Link to={selectedMember.website} className='btn border me-2 btn-md' target='_blank'><FontAwesomeIcon icon="fa-brands fa-edge-legacy" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-sm-12'>
                <div className='chairman-text'>
                  <h4 className='fw-bold1'>Biography</h4>
                  <div className='content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedMember.description) }}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AboutPageDetails;
