import React from 'react';
import config from '../../config';
import { Link } from 'react-router-dom'; 

const Entrepreuners =  ({reportData}) => {

  return (
    <section className='entrepreneurs'>
        <div className='entrepreneurs-leaf1'>
            <img src={config.FILE_URL + 'leaf-1.png'} alt="" className='img-fluid' />
        </div>
        <div className='container'>
            <div className='row'>
              <div className="page-title text-center">
                  <p><span>Entrepreneurs</span></p>
              </div>
              <div className='col-md-6 col-sm-12'>
                  <div className='entrepreneur d-flex'>
                     <div className='entrepreneur-img'>
                         <img src={config.FILE_URL + 'enterp-1.jpg'} alt="" className="img-fluid" />
                     </div>
                     <div className='entrepreneur-text pt-4'>
                        <p className='name mb-0 pb-0'>Taslima Akter</p>
                        <p className='profession'>Ladies Tailors and Basthro Bitan</p>
                     </div>
                  </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                  <div className='entrepreneur d-flex'>
                     <div className='entrepreneur-img'>
                         <img src={config.FILE_URL + 'enterp-2.jpg'} alt="" className="img-fluid" />
                     </div>
                     <div className='entrepreneur-text pt-4'>
                        <p className='name mb-0 pb-0'>Md Arif Hossain</p>
                        <p className='profession'>Junaed Packaging</p>
                     </div>
                  </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                  <div className='entrepreneur d-flex'>
                     <div className='entrepreneur-img'>
                         <img src={config.FILE_URL + 'enterp-3.jpg'} alt="" className="img-fluid" />
                     </div>
                     <div className='entrepreneur-text pt-4'>
                        <p className='name mb-0 pb-0'>Josna Akter</p>
                        <p className='profession'>Kabir Twisting</p>
                     </div>
                  </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                  <div className='entrepreneur d-flex'>
                     <div className='entrepreneur-img'>
                         <img src={config.FILE_URL + 'enterp-4.jpg'} alt="" className="img-fluid" />
                     </div>
                     <div className='entrepreneur-text pt-4'>
                        <p className='name mb-0 pb-0'>Josna Akter</p>
                        <p className='profession'>Angela Beauty Parlour </p>
                     </div>
                  </div>
              </div>

              <div className='col-md-6 col-sm-12'>
                  <div className='entrepreneur d-flex'>
                     <div className='entrepreneur-img'>
                         <img src={config.FILE_URL + 'enterp-5.jpg'} alt="" className="img-fluid" />
                     </div>
                     <div className='entrepreneur-text pt-4'>
                        <p className='name mb-0 pb-0'>Shimu Akter</p>
                        <p className='profession'>Shimu dairy farm</p>
                     </div>
                  </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='enterpreneur-btn'>
                    <Link to={config.BASE_URL + './success-story'} className='see-more'>More Details</Link>
                </div>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Entrepreuners;

