import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const WellcomeSection = () =>{
    const [shortInfoJasonData, setShortInfoJasonData] = useState([]);

    async function fetchShortInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL);
            if(response.status === 200){
                setShortInfoJasonData(response.data.data[0]);
                console.log(shortInfoJasonData);
           }
           else {
               console.error('Unexpected status code:', response.status);
           }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchShortInfo();
      }, []);
    return(
        <>
            <section className="wellcome-section-2">
                <div className="leaf-position-right">
                    <img src={config.FILE_URL + 'leaf-1.png'} alt="" className="img-fluid py-5" />
                </div>
                <div className="container mx-auto">
                    <div className="page-title">
                        <p>Our <span>Program</span> - Nobin Udyokta</p>
                    </div>
                    <div className="wellcome-content text-center">
                        <p className='fw-bold'>
                            <span>Nobin Udyokta-NU (New Entrepreneur) Program</span> is a youth-entrepreneurship development program for the next generation of Grameen family to turn unemployment into entrepreneurship.
                        </p>
                        <p className='text-start'>
                            It is aimed for the children of Grameen Bank members aged between 18-35 years. The aged limit is relaxed for females to encourage more female entrepreneurs.
                        </p>
                    </div>                                     
                </div>
            </section>
            <section className="what-we-doing">
                <div className="container">
                    <div className="page-title text-center">
                        <p><span>What We</span> Doing</p>
                    </div>  
                    <div className="d-flex">
                        <div className='level col-md-4 col-lg-4 col-sm-12'>
                            <img src={config.FILE_URL + 'nu-logo.png'} alt="" className="img-fluid py-5" />
                        </div>
                        <div className='status col-md-8 col-lg-8 col-sm-12 d-flex align-items-center justify-content-center'>
                            <div className="col">
                                <div className='items'>
                                    <p className='m-0 p-0'>21441</p>
                                    <p className='mt-2 mb-0 p-1'>
                                        <i class="fa-solid fa-person"></i> 17869  <i class="fa-solid fa-person-dress"></i> 3572
                                    </p>
                                    <p className='m-0 p-0'>Nobin Udyoktas</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className='items'>
                                    <p className='m-0 p-0'>17</p>   
                                    <p className='mt-2 mb-0 p-1'>Operating Areas</p>
                                    <p className='m-0 p-0'>In Bangladesh</p>
                                </div>
                            </div>
                        </div>
                    </div>                                      
                </div>
            </section>
        </>
        
    )
}
export default WellcomeSection;