import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';
import { Swiper, SwiperSlide} from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import config from '../../config';

// import FaqArea from './FaqArea'
function GalleryPage() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Wellcome />
            <div className='container my-0'>
              <div className='row'>
                <div className='col-md-4 my-3 col-sm-12 col-lg-4 image-box'>
                  <div className='row px-4'>
                    <Swiper
                      effect={'cards'}
                      grabCursor={true}
                      modules={[EffectCards]}
                      className="mySwiper"
                    >
                      <SwiperSlide><img src={config.FILE_URL + "g1a.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1b.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1c.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1d.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1e.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className='col-md-4 my-3 col-sm-12 col-lg-4 image-box'>
                  <div className='row px-4'>
                    <Swiper
                      effect={'cards'}
                      grabCursor={true}
                      modules={[EffectCards]}
                      className="mySwiper"
                    >
                      
                      <SwiperSlide><img src={config.FILE_URL + "g1b.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1c.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1a.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1d.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1e.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className='col-md-4 my-3 col-sm-12 col-lg-4 image-box'>
                  <div className='row px-4'>
                    <Swiper
                      effect={'cards'}
                      grabCursor={true}
                      modules={[EffectCards]}
                      className="mySwiper"
                    >
                      <SwiperSlide><img src={config.FILE_URL + "g1c.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1d.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1a.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1b.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                      <SwiperSlide><img src={config.FILE_URL + "g1e.webp"} alt='' className="img img-fluid h-100 w-100 rounded"/></SwiperSlide>
                    </Swiper>
                  </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default GalleryPage;