import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Letters =  ({reportData}) => {

  return (
    <section className='py-4 latter'>
        <div className='container'>
          <div className='row'>
            <img src={config.FILE_URL + 'latter.png'} alt="" className='' />
          </div>
        </div>
    </section>
  )
}

export default Letters;
