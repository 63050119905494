import React from 'react';
import config from '../../config';
import { Link } from 'react-router-dom'; 

const Programs =  ({reportData}) => {

  return (
    <section className='py-4 programs home-programs'>
        <div className="leaf-position-right">
          <img src={config.FILE_URL + 'leaf-1.png'} alt="" className="img-fluid py-5" />
        </div>
        <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-lg-6 col-sm-12'>
                <div className='programs-text'>
                    <p className='programs-title'>New entrepreneur Program</p>
                    <p className='programs-content'>Grameen Shakti SamajikByabosha Ltd. is working towards poverty eradication and bringing social equity by creating new entrepreneurs (NobinUdyokta).</p>
                    
                    <Link to="/our-programs" className="btn ssbl-btn-primary mt-5">Read More</Link>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-sm-12'>
                <div className='programs-img'>
                  <img src={config.FILE_URL + 'home-programs.png'} alt="" className="img-fluid py-5" />
                </div>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Programs;

