import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const WellcomeSection = () =>{
    const [shortInfoJasonData, setShortInfoJasonData] = useState([]);

    async function fetchShortInfo() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.PAGES_URL);
            if(response.status === 200){
                setShortInfoJasonData(response.data.data[0]);
           }
           else {
               console.error('Unexpected status code:', response.status);
           }
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchShortInfo();
      }, []);
    return(
        <>
            <section className="wellcome-section">
                    <div className="container">                        
                        <div className="row">
                            <div className="col-md-6">
                                <img src={config.FILE_URL+ 'welcome-img.png'} alt="" className="img-fluid" />
                                {/* <img src="welcome-img.png" alt="" className="img-fluid" /> */}
                            </div>
                            <div className="col-md-6">
                                <div className="section-header">
                                    <h4>Welcome to <span>Grameen </span> <span> SSBL</span>.</h4>
                                    <p className="pt-3">Grameen Shakti SamajikByabosa Ltd. is one of the Social Business Companies in the Grameen family. Grameen Shakti Samajik Byabosha Ltd is an organization that aims to be financially successful while creating a positive social and environmental impact. Founded in 2010 by Nobel Laureate Professor Muhammad Yunus, GSSBL is a non-dividend company registered under the Companies Act. 1994 which was created to address and solve social problems. Its headquarters is a registered office at the Grameen Telecom Bhaban, 53/1 Box Nagar, Zoo Road, Mirpur-01, Dhaka-1216, Bangladesh.</p>
                                    <h5><span>G-SSBL</span> <span>OBJECTIVES:</span></h5>
                                    <p>
                                        <ul>
                                            <li>Creating self-employment and jobs for others.Allowing creative entrepreneurs to start their company with a small or without initial investment.</li>
                                            <li>Enhance the socio-economic status of the poor families in the country.To help and accompany new entrepreneurs to develop and establish their businesses.</li>
                                            <li>Empower poor entrepreneurs (Especially women) through financial and technical support as well as promotion.</li>
                                            <li>Organizing training and workshops.</li>
                                            <li>Creating social business villages.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
        
    )
}
export default WellcomeSection;