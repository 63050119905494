import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';
import config from '../../config';

function SuccessStoryPage() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Wellcome />
            <section className="nobin-section mx-auto">
                <div className="container">
                    <div className="page-title py-0">
                        <p>Our <span>Nobins</span></p>
                    </div>
                    <div className='row my-5 py-3'>
                        <div className='col-6 image-box p-4'>
                            <img src={config.FILE_URL + 'enterp-1.jpg'} alt="" className="img-fluid h-100" />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <h4>Ladies Tailors and Basthro Bitan</h4>
                            <h2>-Taslima Akter</h2>
                            <p style={{fontSize:'14px'}}>
                                Mst.Taslima Akter is a NU of 40 years old who lives in Nagarpur,
                                Tangail, Bangladesh. Her mother is Grameen Bank’s member for 20 
                                long years. She started her cloth business with her mother’s money
                                47.58 USD (BDT.4000).At the age of 18, she got married. She 
                                was not happy in her married life. Her husband often tortured her.
                                She could not make it further. She got separated from her husband.
                                But it was very tough for her to survive then in such a
                                struggling situation. She rented a small shop for running her
                                business. Due to shortage of capital she took assistance capital
                                1784 USD (BDT.1, 50,000) and 2nd time equity fund is BDT.3, 
                                50,000 (USD.4163) from Grameen Shakti Samajik Byabosha Ltd. 
                                to expand her business with more goods. Situation favored her
                                and she made a good sale with enough profit. 3rd time BDT.6, 
                                50,000(USD 7653) and 4th time BDT. 10, 00,000(USD 11,677)
                                Now four shops and additional with 6 employees.
                                She is now happy and contributes to the family; reduce poverty and 
                                creating more employment.
                            </p>
                        </div>
                    </div>
                    <div className='row my-5 py-3'>                        
                        <div className='col-md-6 col-sm-12'>
                            <h4>Junaed Packaging</h4>
                            <h2>-Md Arif Hossain</h2>
                            <p style={{fontSize:'14px'}}>
                                Md.Arif Hossain lives in kalihati Tangail District. His mother is a member of Grameen Bank. His father Md. Shahalam was a farmer. Among 3 siblings Md.Arif Hossain is the eldest. They did not have another source of income. Arif Hossain could not complete class eight at school and started his journey for earning livelihood. He worked in a box factory. He became an expert on this task. Then he realized that he could establish a box factory of his own. After his father's death, he started his own factory investing BDT.85, 000 (USD 1000) only. In 2018, Grameen Shakti Samajik Byabosha Ltd. approved his project and he received BDT.1, 50,000 (USD 1766) as equity fund and 2nd time GSSBL equity fund is BDT.3, 00,000 (USD 3532) for expansion his business. He is now running business successfully. Now four shops and 20 persons are working at his box factory which means he had been able to contribute the creating employment opportunity from his ability. His dream is to enlarge his factory more.
                            </p>
                        </div>
                        <div className='col-md-6 col-sm-12 image-box p-4'>
                            <img src={config.FILE_URL + 'enterp-2.jpg'} alt="" className="img-fluid h-100" />
                        </div>                        
                    </div>
                    <div className='row my-5 py-3'>
                        <div className='col-md-6 col-sm-12 image-box p-4'>
                            <img src={config.FILE_URL + 'enterp-3.jpg'} alt="" className="img-fluid h-100" />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <h4>Kabir twisting</h4>
                            <h2>-Kabir Hossain</h2>
                            <p style={{fontSize:'14px'}}>
                                Kabir Hossain is one of the finest entrepreneurs of Grameen shakti samajik Byabosha Ltd. He was born in Mirershawrai village of Panchasar Thana under Munshiganj District. Kabir could study so long due to poverty. His mother Rina is a member of Grameen Bank since 2005. His father Mr.Tara mia is a poor business man. But the income was to little to manage the family expenses. Kabir worked in a yarn factory and learnt tasks there. His mother gave him BDT 40,000(USD473) for business. He started a yarn and rope factory in a small arrangement. Kabir is hard working and he tried his best to expand the business but the lack of enough capital became the prior limitation. When he was struggling with his dream, he suddenly came to know about GSSBL from his known person. The project was approved on September 2017 and he received 1st time BDT 80,000(USD 991).He bought 2 new machines to improve production.2nd time equity investment by GSSBL BDT 1, 00,000 (USD 1183). He expands his business and bought 3 additional machines. Now he has 8 employees in his factory. Kabir wants to widen his business and create opportunity for the unemployed people of the society.
                            </p>
                        </div>
                    </div>
                    <div className='row my-5 py-3'>                        
                        <div className='col-md-6 col-sm-12'>
                            <h4>Angela Beauty Parlour and Training Centre</h4>
                            <h2>- Josna Akter</h2>
                            <p style={{fontSize:'14px'}}>
                                Ms. Josna Akter (36) is the best example of NU. In 2015 she has start her beauty parlor and training centre business by the seed fund of 4,000tk (USD 50) from her mother. Took equity of 50,000 tk(USD 600) from Grameen Shakti Samajik Byabosha Ltd. 2nd time equity investment by GSSBL BDT 1,00000(USD1200). Now she has beauty parlor and training centre and Crockery’s shops and additional three employees. 3rd and 4th time she borrowed total BDT 6, 00,000 (USD7000) for expansion her business. Now a day’s her own investment is BDT 6, 00,000(USD 7000) and total investment is 12, 00,000 (USD14000). Last two years 52 members have been offered training in her training centre and all of them got job in beauty parlour and 13 of them have open their own parlour. She has created an example of self-entrepreneurship in her locality. Her efforts will lead to create more job opportunities where women would be prioritized to work.
                            </p>
                        </div>
                        <div className='col-md-6 col-sm-12 image-box p-4'>
                            <img src={config.FILE_URL + 'enterp-4.jpg'} alt="" className="img-fluid h-100" />
                        </div>                        
                    </div>
                    <div className='row my-5 py-3'>
                        <div className='col-md-6 col-sm-12 image-box p-4'>
                            <img src={config.FILE_URL + 'enterp-5.jpg'} alt="" className="img-fluid h-100" />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <h4>Shimu dairy farm</h4>
                            <h2>-Shimu Akter</h2>
                            <p style={{fontSize:'14px'}}>
                                Shimu is a successful entrepreneur of Grameen Shakti Samajik Byabosha Ltd. Shimu has proved it. She was born in kapasia,Gazipur in 1993.Her mother Baby akter has been a member of Grameen Bank and paying her installments regularly. Shimu studied till class ten. Unfortunately she could not pass the SSC exam. She got married. She took money from her husband and bought a cow. She planned to widen her business and make her firm bigger. Then she joined GSSBL in 2020.She took equity of 1st time BDT1, 00000 (USD 1178) and 2nd time 2, 50,000 taka (USD 2919) and bought one more cows and food for those cows. At present she has 10 cows producing approximately 40 liters milk per day and which market value is about BDT 2600. She is doing her business with great joy. Shimu wants to make her business much bigger so that she can contribute the society by eradicating unemployment problem and creating development in her area.
                            </p>
                        </div>
                    </div>                    
                </div>
            </section>
        </div>
    )
}
export default SuccessStoryPage;