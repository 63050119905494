import React, { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import { Link } from 'react-router-dom';

// import FaqArea from './FaqArea'
function CareerPage() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
      <section className="wellcome-section-2 career-page">
          <div className="container">
              <div className="page-title">
                  <p>GSSBL <span>Career</span></p>
              </div>
              <div className="career-card my-3 p-4 rounded">
                  <h4 className="fw-bold">GSSBL is looking for Sr. Officer IT</h4>
                  <div>
                    <strong>Vacancy:</strong> 5  &nbsp; &nbsp; | &nbsp; &nbsp;
                    <strong>Job Location:</strong> Dhaka (Head Office) &nbsp; &nbsp; | &nbsp; &nbsp;
                    <strong>Job Type:</strong> Full Time
                  </div>
                  <div className="job-details">
                      <strong>Application Start:</strong> 12-03-2022
                      &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;
                      <strong>Application End:</strong> 12-04-2022
                  </div>
                  <Link to={config.BASE_URL + './career-details/1'} className='btn btn-sm ssbl-btn-primary mt-3'>Job Details</Link>
              </div>
              <div className="career-card my-3 p-4 rounded">
                  <h4 className="fw-bold">GSSBL is looking for Sr. Officer IT</h4>
                  <div>
                    <strong>Vacancy:</strong> 5  &nbsp; &nbsp; | &nbsp; &nbsp;
                    <strong>Job Location:</strong> Dhaka (Head Office) &nbsp; &nbsp; | &nbsp; &nbsp;
                    <strong>Job Type:</strong> Full Time
                  </div>
                  <div className="job-details">
                      <strong>Application Start:</strong> 12-03-2022
                      &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;
                      <strong>Application End:</strong> 12-04-2022
                  </div>
                  <Link to={config.BASE_URL + './career-details/1'} className='btn btn-sm ssbl-btn-primary mt-3'>Job Details</Link>
              </div>
              <div className="career-card my-3 p-4 rounded text-center py-5">
                  <h4 className="fw-bold">No Job Available!</h4>                  
                  <div className="job-details">
                        Drop Your Resume for Internship
                  </div>
                  <Link to={config.BASE_URL + './career-details/1'} className='btn btn-sm ssbl-btn-primary mt-3'>Drop Resume</Link>
              </div>
          </div>
      </section>
    )
}
export default CareerPage;